@mixin for-tablet-up {
    @media (min-width: 48rem) {
        @content;
    }
}

@mixin breakpoint-xs {
    @media (min-width: 0px) {
        @content;
    }
}

@mixin breakpoint-sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin breakpoint-md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin breakpoint-lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin breakpoint-xl {
    @media (min-width: 1232px) {
        @content;
    }
}

@mixin layer($layer, $nested-layer: "") {
    @if $nested-layer == "" {
        @layer #{$layer} {
            @content;
        }
    } @else {
        @layer #{$layer}.#{$nested-layer} {
            @content;
        }
    }

    // You can not currently use `@support` for any `at-rule` so to understand if something could support layer I looked for
    // Adjacent features. The `:has` selector has a similar support except slightly higher in all cases.
    // layer:
    // chrome: 99
    // edge: 99
    // safari: 15.4
    // firefox: 97
    // Samsung Internet: 8
    // :has:
    // chrome: 105
    // edge: 105
    // safari: 15.4
    // firefox: 121
    // Samsung Internet: 20

    @supports not selector(a:has(img)) {
        @content;
    }
}
